import React from 'react'
import { Helmet } from 'react-helmet'
import Header from '../components/03 Organisms/header'
import Footer from '../components/03 Organisms/footer'

const Index = () => {    
    return(
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="404 page not found" />
          <title>404 Page not found</title>
        </Helmet>
        <Header />
        <section className="hero hero--inspiration">
          <div className="hero__splash">
            <div className="hero__title">
              <h1>404 – Page not found</h1>
            </div>
          </div>
        </section>            
        <main className="landing_page 404">
        </main>
        <Footer />
        </>
    )
}
  
  export default Index;